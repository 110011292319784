import React from 'react'

import Navigation from 'components/nav'

import './nav.scss'

class MobileNav extends React.Component {

  componentDidMount() {
    this.menuIcon = document.querySelector(`#menuIcon`)
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (e) => {
    if (!this.props.showMobileNav) return;
    if (this.menu && !this.menu.contains(e.target) && !this.menuIcon.contains(e.target)) {
      this.props.toggleMobileNav();
    }
  }

  render() {
    let { showMobileNav } = this.props

    return (
      <div
        className={"c-mobile-nav " + (showMobileNav ? 'mobile-nav-visible' : '')}
        ref={(el) => this.menu = el}
      >
        <Navigation className="" />
      </div>
    )
  }
}

export default MobileNav
