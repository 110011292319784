import React from 'react'
import { Link } from 'gatsby'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import './modal.scss'

class Modal extends React.Component {

  componentDidMount() {
    this.bodyElement = document.querySelector(`body`)
    this.bodyElement.style.overflow = `hidden`
  }

  componentWillUnmount() {
    this.bodyElement.style.overflow = `auto`
  }

  render() {
    let { modalBackground, children } = this.props
    return (
      <div className="c-modal-overlay">
        <div className="c-modal">
          <Link to={modalBackground}><FontAwesomeIcon icon={faTimes} /></Link>
          {children}
        </div>
      </div>
    )
  } 
}

export default Modal
