import React from 'react'
import { StaticQuery, Link, graphql } from 'gatsby'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretRight, faGraduationCap } from '@fortawesome/free-solid-svg-icons'

import './nav.scss'

const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent
    ? { "aria-current": "page" }
    : null
}

const Navigation = ({ data = {}, className = "l-nav" }) => (
  <StaticQuery
    query={graphql`
      query { 
        allJobs: allMarkdownRemark(
          filter: { 
            fields: { 
              type: { eq: "jobs"}
            }
          }, 
          sort: {fields: [frontmatter___position], order: ASC}
        ) {
          totalCount
        }
      }
    `}
    render={data => (
      <nav className={className}>
        <ul>
          <li><Link to={'/'} title="Front Page"><span>Home</span></Link></li>
          <li><Link to={'/blog'} getProps={isPartiallyActive}><span>Blog</span></Link></li>
          <li>
            <Link to={'/fundamentals'} getProps={isPartiallyActive}>Fundamentals <FontAwesomeIcon icon={faCaretDown} /></Link>
            <ul>
              <li><Link to={'/fundamentals/family-farming'}>Family Farming</Link></li>
              <li><Link to={'/fundamentals/farm-team'}>Farm Team</Link></li>
              <li><Link to={'/fundamentals/farm-history'}>Farm History</Link></li>
              <li>
                <Link to={'/fundamentals/what-we-grow'} getProps={isPartiallyActive}>What We Grow <FontAwesomeIcon icon={faCaretDown} /> <FontAwesomeIcon icon={faCaretRight} /></Link>
                <ul>
                  <li><Link to={'/fundamentals/what-we-grow/tomatoes'}>Tomatoes</Link></li>
                  <li><Link to={'/fundamentals/what-we-grow/grains'}>Grains</Link></li>
                </ul>  
              </li>
              <li><Link to={'/fundamentals/sustainability'}>Sustainability</Link></li>
            </ul>  
          </li>
          <li>
            <Link to={'/industry-and-community'} getProps={isPartiallyActive}>Industry &amp; Community <FontAwesomeIcon icon={faCaretDown} /></Link>
            <ul>
              <li><Link to={'/industry-and-community/industry-leadership'}>Industry Leadership</Link></li>
              <li><Link to={'/industry-and-community/community-leadership'}>Community Leadership</Link></li>
            </ul>    
          </li>
          <li><Link to={'/landowners'}>Landowners</Link></li>
          <li>
            <Link to={'/careers'} getProps={isPartiallyActive}>Careers <FontAwesomeIcon icon={faCaretDown} /></Link>
            <ul>
              <li><Link to={'/careers/open-positions'} getProps={isPartiallyActive}>Open Positions <span className="label">{data.allJobs.totalCount}</span></Link></li>
              <li><Link to={'/careers/company-culture'}>Company Culture</Link></li>
              <li><Link to={'/careers/core-values'}>Core Values</Link></li>
            </ul> 
          </li>
          <li><Link to={'/contact-us'}>Contact Us</Link></li>
        </ul>
      </nav>
    )}
  />
)

export default Navigation
