import React from 'react'
import { Link } from 'gatsby'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faBars, faCloud } from '@fortawesome/free-solid-svg-icons'
import Navigation from 'components/nav'

import logo from 'images/logo.png'
import './header.scss'

const Header = ({ toggleMobileNav }) => (
  <header className="l-header">
    <div className="container l-header__container">
      <Link to={'/'} className="l-header__logo"><img src={logo} alt="Logo" /></Link>    
      <Link to={'/'}>Howell Farms <span>of Crossroads</span></Link>
      <a 
        href="http://forecast.weather.gov/MapClick.php?lat=40.05769036102242&lon=-85.53628606524893" 
        className="l-header__link--weather"
        target="_blank"
        rel="noopener noreferrer"
        title="Weather for Middletown, IN"><FontAwesomeIcon icon={faCloud} /></a>
      <a 
        href="https://www.facebook.com/howellfarmsmiddletown" 
        className="l-header__link--facebook"
        target="_blank"
        rel="noopener noreferrer"
        title="Like us on Facebook!"><FontAwesomeIcon icon={faFacebook} /></a>
      <FontAwesomeIcon icon={faBars} id="menuIcon" onClick={toggleMobileNav} />
      <Navigation />
    </div>
  </header>
)

export default Header
