import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, PageRenderer, graphql } from 'gatsby'

import Header from 'components/header'
import Footer from 'components/footer'
import Modal from 'components/modal'
import MobileNav from 'components/nav/mobile-nav'

import '../../styles/_layout.scss'
import favicon from 'images/favicon.ico'

class Layout extends React.Component {

  state = {
    showMobileNav: false
  };

  toggleMobileNav = () => {
    this.setState({ 
      showMobileNav: !this.state.showMobileNav 
    })
  }

  render() {
    let { isModal = false } = this.props
    let { showMobileNav } = this.state

    if(isModal) {
      return (
        <>
          <PageRenderer location={{ pathname: this.props.modalBackground }} />
          <Modal modalBackground={this.props.modalBackground}>
            {this.props.children}
          </Modal>
        </>
      )
    }

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            articles: allMarkdownRemark(
              filter: { 
                fields: { 
                  type: { eq: "articles"}
                }
              }, 
              sort: {fields: [frontmatter___date], order: DESC},
              limit: 4
            ) {
              edges {
                node {
                  fields {
                    type
                    slug
                  }
                  frontmatter {
                    title
                    date(formatString: "MMMM DD, YYYY")
                  }
                }
              }
            }
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                { name: 'description', content: 'Sample' },
                { name: 'keywords', content: 'sample, something' },
              ]}
              link={[
                { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }
              ]}
            />
            <MobileNav showMobileNav={showMobileNav} toggleMobileNav={this.toggleMobileNav} />
            <div className={"nav-wrapper " + (showMobileNav ? 'mobile-nav-visible' : '')}>
              <Header 
                siteTitle={data.site.siteMetadata.title} 
                toggleMobileNav={this.toggleMobileNav} 
              />
              {this.props.children}
              <Footer articles={data.articles.edges} />
            </div>
            <script
              dangerouslySetInnerHTML={{
                __html: `
                var $buoop = {required:{i:12},reminder:0,insecure:true,api:2018.09,text: "Your browser is too old to view the Howell Farms website properly. Consider upgrading to a modern web browser. <a {up_but}>Update</a>"}; 
                function $buo_f(){ 
                var e = document.createElement("script"); 
                e.src = "//browser-update.org/update.min.js"; 
                document.body.appendChild(e);
                };
                try {document.addEventListener("DOMContentLoaded", $buo_f,false)}
                catch(e){window.attachEvent("onload", $buo_f)}
                `
              }}
            />
          </>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}


export default Layout
