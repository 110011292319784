import React from 'react'
import { Link } from 'gatsby'

import Banner from 'components/banner'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'

import './footer.scss'

const Footer = ({ articles }) => (
  <>
    <Banner />
    <footer className="l-footer">
      <section>
        <h3>Farm Purpose</h3>
        <p className="purpose">To provide an acceptable livelihood in production agriculture for all involved through all manner of cyclical events by using fully the skills, knowledge, associations and ambitions of all involved in agricultural business activities from which we derive personal and professional satisfaction, and to return generously to those in need from the prosperity granted us by the grace of God.</p>
      </section>
      <section>
        <h3>From The Blog</h3>
        <ul>
          {articles.map(article => (
            <li key={article.node.fields.slug}><Link to={article.node.fields.slug}>{article.node.frontmatter.title}</Link></li>
          ))}
        </ul>
        <Link to={'/blog'} className="buttonLink--small">Read the blog</Link>
      </section>
      <section>
        <address>
          <h3><Link to={'/contact-us'}>Contact Information</Link></h3>
          <span><em>Howell Farms of Crossroads LLC</em></span>
          <span>12261 S. County Road 600 W.</span>
          <span>Middletown, IN 47356</span>
          <span><em>Phone:</em> <a href="tel:17657597432">(765) 759-7432</a></span>
          <a 
        href="https://www.facebook.com/howellfarmsmiddletown" 
        className="l-header__link--facebook"
        target="_blank"
        rel="noopener noreferrer"
        title="Like us on Facebook!"><FontAwesomeIcon icon={faFacebook} /></a>
        </address>
      </section>
    </footer>
    <small>
      <p>
        Copyright &copy; {(new Date).getFullYear()} Howell Farms of Crossroads LLC. All rights reserved.
        <Link to={'/contact-us'}>Contact Us</Link>
        <Link to={'/careers'}>Careers</Link>
        <a href="http://forecast.weather.gov/MapClick.php?lat=40.05769036102242&lon=-85.53628606524893" target="_blank" rel="noopener noreferrer">Local Weather</a>
      </p>
    </small>
  </>
)

export default Footer
