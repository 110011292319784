import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSeedling } from '@fortawesome/free-solid-svg-icons'

import './banner.scss'

const Banner = () => (
  <div className="c-banner">
    <p>
      <span>Howell Farms</span>
      <FontAwesomeIcon icon={faSeedling} />
      <span>Growing With Gratitude</span></p>
  </div>
)

export default Banner
